import React from 'react';
import Img from 'gatsby-image';
import { Divider, Row, Col, Grid } from '@components/Grid';
import { Link } from 'gatsby';

import styles from './styles.module.scss';
import Button from '@components/Button';
import ScrollTextWithImage from '@components/ScrollTextWithImage';

const WebDevelopment = ({ imageBottom, imageTop, imageMiddle }) => {
    return (
        <section className={styles.webDevelopmentWrapper}>
            <Grid>
                <div className={styles.methodologyWrapper}>
                    <ScrollTextWithImage imageFluid={imageTop}>
                        <Divider xsMargin={5} mdMargin={0} />
                        <h2>
                            Built in a modern environment for speed, attention & conversion.
                        </h2>
                        <p>
                            Our sites outperform the competition <Link to="#glossary=lighthouse">Lighthouse</Link>  tests for speed and
                            accessibility.
                        </p>
                        <p>Want to know more about our web-development methodology?</p>
                        <Link to="/service/web-platforms/web-development">
                            <Button text="Read more" />
                        </Link>
                    </ScrollTextWithImage>
                </div>
            </Grid>

            <Divider margin={5} />
            <Divider xsMargin={0} mdMargin={5} />

            <div className={styles.webDevelopmentConclusionWrapper}>
                <Divider margin={5} />
                <Grid>
                    <Row>
                        <Col xs={12} md={6}>
                            <Img fluid={imageMiddle} imgStyle={{ objectFit: 'cover' }} />
                        </Col>
                        <Col xs={12} md={6}>
                            <Divider xsMargin={5} mdMargin={0} />
                            <h2>An image to be proud of.</h2>
                            <p>
                                Our illustrators created a special atmosphere for LiveYou. The
                                visuals are welcoming while maintaining a professional aura.
                            </p>
                            <p>
                                We listened to LiveYou’s target market - many disabled peoples use
                                iPad and tablets to navigate a digital world. For this reason the
                                LiveYou site was designed tablet first to ensure the best UX for a
                                tablet user. We also considered and re-evaluated the functionality
                                of hovers and other inaccessible functions.
                            </p>
                            <p>Interested in our illustration services?</p>
                           <Link to="/service/graphic-design/layout-design-and-print">
                               <Button text="Read more" />
                           </Link>
                        </Col>
                    </Row>
                </Grid>

                <Divider xsMargin={0} mdMargin={5} />
                <Img fluid={imageBottom} imgStyle={{ objectFit: 'cover' }} />
            </div>
        </section>
    );
};

export default WebDevelopment;
