import React from 'react';
import { Divider, Row, Col, Grid } from '@components/Grid';
import { graphql, Link } from 'gatsby';
import Layout from '@components/Layout';
import SEO from '@components/Seo';

import styles from './styles.module.scss';
import Hero from '@views/case-studies/Hero';
import ProjectOverview from '@views/case-studies/ProjectOverview';
import Description from '@views/case-studies/Description';
import Branding from '@views/case-studies/liveyou/Branding';
import WebDevelopment from '@views/case-studies/liveyou/WebDevelopment';
import ReadMore from '@views/case-studies/ReadMore';

const Liveyou = ({ data }) => {
    return (
        <Layout>
            <SEO
                title="LiveYou • Case Studies"
                image={data?.gundiesPreview?.publicURL}
                description=" We not only helped Liveyou by redesigning their website but we equipped them with all the social marketing devices they need to drive accurately targeted market traffic to their website."
            />
            <div className={styles.caseStudyWrapper}>
                <Hero
                    title="Redefining a digital presence."
                    text="We not only helped Liveyou by redesigning their website but we equipped them with all the social marketing devices they need to drive accurately targeted market traffic to their website."
                    image={data?.hero?.publicURL}
                    backgroundColor="#F0FBFF"
                />

                <ProjectOverview
                    client="LiveYou"
                    name="Disability services website."
                    backgroundColor="#00C9EF"
                    description={
                        <p>
                            Live you used our{' '}
                            <Link to={'/service/web-platforms/web-design'}>Web-Design</Link> to{' '}
                            <Link to={'/service/web-platforms/web-development'}>
                                Web-Development
                            </Link>{' '}
                            services to relaunch their digital presence to increase their
                            nation-wide reach. We also provided{' '}
                            <Link to={'/service/graphic-design/animation'}>Animation</Link> services
                            along side{' '}
                            <Link to={'/service/graphic-design/marketing'}>Social Marketing</Link>{' '}
                            consultancy services.
                        </p>
                        // <p>
                        //     Started out using our standard{' '}
                        //     <Link to={'/service/web-platforms/web-design'}>Web-Design</Link> to{' '}
                        //     <Link to={'/service/web-platforms/web-development'}>
                        //         Web-Development
                        //     </Link>{' '}
                        //     flow. This was then complimented by some light duty{' '}
                        //     <Link to={'/service/graphic-design/animation'}>Animation</Link> work and
                        //     then finalised by our{' '}
                        //     <Link to={'/service/graphic-design/marketing'}>
                        //         Social Media Marketing
                        //     </Link>{' '}
                        //     templates that
                        // </p>
                    }
                />

                <Divider margin={5} />
                <Divider xsMargin={0} mdMargin={5} />

                <Description
                    image={data?.description?.childImageSharp?.fluid}
                    textLeft={
                        ' LiveYou is a alternate track disability pairing company. They match disabled persons to personal assistants (PAs). Born out of providing a better service than the traditional council route.'
                    }
                    textRight={
                        <div>
                            <p>
                                We found their existing website to lack the cutting edge required to
                                convert views into clicks. It was built on popular web-site builder
                                Wix. This resulted in poor <Link to="#glossary=UX">UX</Link> and
                                colours that clashed resulting into difficult to read text.
                            </p>

                            <p>
                                We rectify these issues Element Softworks redesigned the site using
                                contemporary design methods that expect to exceed a lifetime of 10
                                years. This is then built using bespoke handcrafted{' '}
                                <Link to="#glossary=React">React</Link> code for ultimate
                                customisability and speed.
                            </p>
                        </div>
                    }
                />

                <Divider margin={5} />
                <Divider xsMargin={0} mdMargin={5} />

                <Branding
                    mosaicGrid={data?.brandingGrid?.childImageSharp?.fluid}
                    siteImage={data?.brandingMobileImage?.childImageSharp?.fluid}
                    sliderImages={[
                        data?.brandingSliderImage01?.childImageSharp?.fluid,
                        data?.brandingSliderImage02?.childImageSharp?.fluid,
                        data?.brandingSliderImage03?.childImageSharp?.fluid,
                    ]}
                    heading={
                        'Our light overhaul of LiveYou gave them strong brand positioning company wide.'
                    }
                    mainText={
                        <div>
                            <p>
                                LiveYou does not have an established brand and neither did they have
                                the budget to develop brand recognition using our branding services
                                however we managed to overcome this. By using the approved design
                                elements used throughout the site, our designers created a package
                                of social media assets. 10 scenarios (announcements, infographics,
                                quotes, etc…) were accounted for which three different template
                                styles to choose for each. Our designers accounted for their
                                Instagram, Facebook & LinkedIn by providing profile and cover photo
                                designs. This included converting their original logo to an SVG for
                                optimum quality.
                            </p>
                            <p>
                                Overall Element Softworks was aware of LiveYou’s potential future
                                issue and address them in a way that coincided within their budget.
                            </p>
                        </div>
                    }
                    bottomText={
                        'Their website is now built on a modern platform ready for the 2020s.'
                    }
                />

                <Divider margin={5} />
                <Divider xsMargin={0} mdMargin={5} />

                <WebDevelopment
                    imageTop={data?.webDevelopmentTop?.childImageSharp?.fluid}
                    imageMiddle={data?.webDevelopmentMiddle?.childImageSharp?.fluid}
                    imageBottom={data?.webDevelopmentBottom?.childImageSharp?.fluid}
                />
                <ReadMore />
            </div>
        </Layout>
    );
};

export default Liveyou;

export const query = graphql`
    query {
        gundiesPreview: file(absolutePath: { regex: "/case-studies-the-gundies-preview.png/" }) {
            publicURL
        }
        hero: file(absolutePath: { regex: "/case-studies-liveyou-hero.svg/" }) {
            publicURL
        }
        description: file(absolutePath: { regex: "/case-studies-liveyou-description.png/" }) {
            childImageSharp {
                fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        brandingGrid: file(absolutePath: { regex: "/case-studies-liveyou-branding-grid.png/" }) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        brandingMobileImage: file(
            absolutePath: { regex: "/case-studies-liveyou-branding-mobile-image.png/" }
        ) {
            childImageSharp {
                fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        brandingSliderImage01: file(
            absolutePath: { regex: "/case-studies-liveyou-branding-slider-image-01.png/" }
        ) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        brandingSliderImage02: file(
            absolutePath: { regex: "/case-studies-liveyou-branding-slider-image-02.png/" }
        ) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        brandingSliderImage03: file(
            absolutePath: { regex: "/case-studies-liveyou-branding-slider-image-03.png/" }
        ) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        webDevelopmentTop: file(
            absolutePath: { regex: "/case-studies-liveyou-web-development-top.png/" }
        ) {
            childImageSharp {
                fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        webDevelopmentMiddle: file(
            absolutePath: { regex: "/case-studies-liveyou-web-development-middle.png/" }
        ) {
            childImageSharp {
                fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        webDevelopmentBottom: file(
            absolutePath: { regex: "/case-studies-liveyou-web-development-bottom.png/" }
        ) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`;
