import React from 'react';
import Img from 'gatsby-image';
import { SwiperSlide } from 'swiper/react';
import { Divider, Row, Col, Grid } from '@components/Grid';
import styles from './styles.module.scss';
import KnowMoreCta from '@components/CaseStudies/KnowMoreCta';
import CustomSwiper from '@components/CustomSwiper';

const Branding = ({
    siteImage,
    desktopDesignImage,
    sliderImages,
    heading,
    mainText,
    bottomText,
}) => {
    return (
        <section>
            <Grid>
                <Row>
                    <Col xs={12}>
                        <div className={styles.brandingHeadingWrapper}>
                            <div className={styles.brandingHeading}>
                                <h2>{heading}</h2>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Grid>

            <Divider margin={5} />
            <Divider xsMargin={0} mdMargin={5} />

            <Divider margin={5} />
            <Divider xsMargin={0} mdMargin={5} />

            <Grid>
                <Row>
                    <Col xs={12} md={6}>
                        {mainText}
                    </Col>
                    <Col xs={12} md={6}>
                        <Img fluid={siteImage} imgStyle={{ objectFit: 'cover' }} />
                    </Col>
                </Row>
            </Grid>

            <Divider margin={5} />
            <Divider xsMargin={0} mdMargin={5} />

            <KnowMoreCta
                text={
                    <h2>
                        Want to know more about <br />
                        Social Marketing?
                    </h2>
                }
                buttonText="Read more"
                link="/service/graphic-design/marketing"
            />

            <CustomSwiper>
                {sliderImages?.map((image) => (
                    <SwiperSlide key={image?.src}>
                        <div>
                            <Img fluid={image} imgStyle={{ objectFit: 'cover' }} />
                        </div>
                    </SwiperSlide>
                ))}
            </CustomSwiper>

            <Divider margin={5} />
            <Divider margin={5} />
            <Divider xsMargin={0} mdMargin={5} />

            <Grid>
                <Row>
                    <Col xs={12}>
                        <div className={styles.bottomHeadingWrapper}>
                            <div className={styles.bottomHeading}>
                                <h2>{bottomText}</h2>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Grid>
        </section>
    );
};

export default Branding;
